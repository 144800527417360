"use strict";


// const baseURL = "https://dipankarmondal-111470-ruby.b111470.dev.us-east-1.aws.svc.builder.cafe";
const baseURL = __MARKER_FOR_BACKEND_URL_REPLACEMENT;

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.baseURL = baseURL; 
